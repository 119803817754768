import React from 'react';
import { Helmet } from 'react-helmet';

function ContactPage() {
  return (
    <div>
      <Helmet>
        <title>ติดต่อเรา</title>
        <meta name="description" content="บริษัท โชคำนชัย พัฒนา จำกัด เราเป็นบริษัทที่ผลิตเเละจัดจำหน่ายผลิตภัณฑ์ทำความสะอาดเเละอุปกรณฑ์ทำความสะอาดครบวงค์จร" />
        <meta name="keywords" content="Choknamchai, Choknumchai Phattana, Industry, Products, Home, โชคนำชัย, เคมีภัณฑ์, หน้าเเรก, สินค้า, อุปกรณ์, อุปกรณ์ทำความสะอาด, น้ำยา, น้ำยาทำความสะอาด, น้ำยาล้างจาน, น้ำยาล้างห้องน้ำ, น้ำยาล้างพื้น, น้ำยาซักผ้า, น้ำยาปรับผ้านุ่ม, เเอลกอฮอล์, Alcohol, เเอลกฮอลเข้มขน, ไม้กวาด, ไม่ถูพื้น, เศษผ้า, น้ำยาเช็ดกระจก, น้ำยาฆ่าเชื้อ, เจล, น้ำยาถูพื้น, น้ำยาปรับผ้านุ่ม, อื่นๆ, คลอรีนน้ำ 10%, ถุงขยะ, ถุงดำ, Size, กระดาษชำระ, กระดาษเช็ดชู่, เช็ดชู่" />
      </Helmet>
      <h1>Contact Us</h1>
      <p>This page contains contact information.</p>
    </div>
  );
}

export default ContactPage;