import React from 'react';
import KnowledgeCard from './KnowledgeCard';
import './KnowledgeSection.css';

const knowledgeData = [
  {
    title: 'HEALTHY CONTENT',
    imageUrl: '/images/KnowledgeBlock/nb.png',
    description: 'Description for healthy content'
  },
  {
    title: 'MOCKTAIL MENU',
    imageUrl: '/images/KnowledgeBlock/nb.png',
    description: 'Description for mocktail menu'
  },
  {
    title: 'LAD',
    imageUrl: '/images/KnowledgeBlock/nb.png',
    description: 'Description for lad'
  }
];

function KnowledgeSection() {
  return (
    <section className="knowledge-section">
      <h2 className="section-title">บล็อกความรู้</h2>
      <div className="knowledge-cards-container">
        {knowledgeData.map((item, index) => (
          <KnowledgeCard
            key={index}
            title={item.title}
            imageUrl={item.imageUrl}
            description={item.description}
          />
        ))}
      </div>
    </section>
  );
}

export default KnowledgeSection;