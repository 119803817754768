import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import products from '../data/products';
import './ProductPage.css';

function ProductPage() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const categories = {
    'สินค้าอุตสาหกรรม': [],
    'สินค้าทั่วไป': [],
    'อุปกรณ์': [],
    'socr': [],
    'สินค้าอื่นๆ': []
  };

  products.forEach(product => {
    if (categories[product.category]) {
      categories[product.category].push(product);
    } else {
      categories['สินค้าอื่นๆ'].push(product);
    }
  });

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="product-page">
      <Helmet>
        <title>สินค้าของเรา</title>
        <meta name="description" content="บริษัท โชคำนชัย พัฒนา จำกัด เราเป็นบริษัทที่ผลิตเเละจัดจำหน่ายผลิตภัณฑ์ทำความสะอาดเเละอุปกรณฑ์ทำความสะอาดครบวงค์จร" />
        <meta name="keywords" content="Choknamchai, Choknumchai Phattana, Industry, Products, Home, โชคนำชัย, เคมีภัณฑ์, หน้าเเรก, สินค้า, อุปกรณ์, อุปกรณ์ทำความสะอาด, น้ำยา, น้ำยาทำความสะอาด, น้ำยาล้างจาน, น้ำยาล้างห้องน้ำ, น้ำยาล้างพื้น, น้ำยาซักผ้า, น้ำยาปรับผ้านุ่ม, เเอลกอฮอล์, Alcohol, เเอลกฮอลเข้มขน, ไม้กวาด, ไม่ถูพื้น, เศษผ้า, น้ำยาเช็ดกระจก, น้ำยาฆ่าเชื้อ, เจล, น้ำยาถูพื้น, น้ำยาปรับผ้านุ่ม, อื่นๆ, คลอรีนน้ำ 10%, ถุงขยะ, ถุงดำ, Size, กระดาษชำระ, กระดาษเช็ดชู่, เช็ดชู่" />
      </Helmet>
      {Object.keys(categories).map((category, index) => (
        <div key={index} className="product-category">
          <h2 className="category-title">{category}</h2>
          <div className="category-list">
            {categories[category].map(product => (
              <div
                key={product.id}
                className="product-item"
                onClick={() => handleProductClick(product)}
              >
                <img src={product.imageUrl} alt={product.name} className="product-image" />
                <h2 className="product-name">{product.name}</h2>
              </div>
            ))}
          </div>
        </div>
      ))}

      {selectedProduct && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <img src={selectedProduct.imageUrl} alt={selectedProduct.name} className="modal-image" />
            <div className="modal-details">
              <h2>{selectedProduct.name}</h2>
              <p>{selectedProduct.description}</p>
              <p className="modal-price">{selectedProduct.price} บาท</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductPage;
