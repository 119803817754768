import React from 'react';
import NewsCard from './NewsCard';
import './NewsSection.css';

const newsData = [
  {
    title: 'Event 1',
    imageUrl: '/images/news/test1.png',
    date: 'January 1, 2024',
    description: 'Description for event 1'
  },
  {
    title: 'Event 2',
    imageUrl: '/images/news/test1.png',
    date: 'February 1, 2024',
    description: 'Description for event 2'
  },
  {
    title: 'Event 3',
    imageUrl: '/images/news/test1.png',
    date: 'March 1, 2024',
    description: 'Description for event 3'
  }
];

function NewsSection() {
  return (
    <section className="news-section">
      <h2 className="section-title">ข่าวสารและกิจกรรม</h2>
      <div className="news-cards-container">
        {newsData.map((item, index) => (
          <NewsCard
            key={index}
            title={item.title}
            imageUrl={item.imageUrl}
            date={item.date}
            description={item.description}
          />
        ))}
      </div>
    </section>
  );
}

export default NewsSection;