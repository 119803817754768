import React, { useState, useEffect } from 'react';
import './Banner.css'; // ไฟล์ CSS สำหรับสไตล์ของ Banner

const images = [
  '/images/banner/wdps.png',
  '/images/banner/bannerproducts.png',
  '/banner3.jpg'
];

function Banner() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [startTouch, setStartTouch] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // สลับภาพทุก 10 วินาที

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handleTouchStart = (e) => {
    setStartTouch(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!startTouch) return;

    const touchEnd = e.touches[0].clientX;
    const touchDiff = startTouch - touchEnd;

    if (touchDiff > 50) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setStartTouch(null);
    } else if (touchDiff < -50) {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setStartTouch(null);
    }
  };

  return (
    <div 
      className="banner-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Banner ${index + 1}`}
          className={`banner-image ${index === currentImageIndex ? 'active' : ''}`}
          style={{ opacity: index === currentImageIndex ? 1 : 0 }}
        />
      ))}
      <div className="dots-container">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default Banner;