const products = [
    {
        id: 1,
        name: 'Alcohol 95% FoodGrade',
        description: 'Description for Product 1',
        price: '',
        imageUrl: '/images/products/AL95.png',
        category: 'สินค้าอุตสาหกรรม'
    },
    {
        id: 2,
        name: 'Alcohol 70% FoodGrade',
        description: 'Description for Product 2',
        price: '',
        imageUrl: '/images/products/AL70.png',
        category: 'สินค้าอุตสาหกรรม'
    },
    {
        id: 3,
        name: 'Alcohol 95% Non-FoodGrade',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/AL95.png',
        category: 'สินค้าอุตสาหกรรม'
    },
    {
        id: 4,
        name: 'Alcohol 95% Non-FoodGrade',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/AL95.png',
        category: 'สินค้าอุตสาหกรรม'
    },
    {
        id: 5,
        name: 'คลอรีนน้ำ 10%',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/SodiumC10.png',
        category: 'สินค้าอุตสาหกรรม'
    },
    {
        id: 6,
        name: 'น้ำยาล้างภาชนะ พรไลท์ 01',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/PS20.png',
        category: 'สินค้าทั่วไป'
    },
    {
        id: 7,
        name: 'น้ำยาซักผ้า พรไลท์ ดีนนี่เฟรช',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/WDP20.png',
        category: 'สินค้าทั่วไป'
    },
    {
        id: 8,
        name: 'น้ำยาซักผ้าพรไลท์ เเฟนตาเซีย เฟรช',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/WDB20.png',
        category: 'สินค้าอุตสาหกรรม'
    },
    {
        id: 9,
        name: 'ผงซักฟอก Dacco',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/Dacco.png',
        category: 'สินค้าอุตสาหกรรม'
    },
    {
        id: 10,
        name: 'ผงซักฟอก Dabbo',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/Dabbo.png',
        category: 'สินค้าอุตสาหกรรม'
    },
    {
        id: 11,
        name: 'น้ำยาปรับผ้านุ่ม พรไลท์ เพอร์ฟูมเเพชชั่น',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/SFP20.png',
        category: 'สินค้าทั่วไป'
    },
    {
        id: 12,
        name: 'กระดาษชำระFin เเบบม้วน 300เมตร',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/FINBLUE300.png',
        category: 'สินค้าอื่นๆ'
    },
    {
        id: 13,
        name: 'กระดาษชำระFin เเบบเเผ่น 1ชั้น',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/FINRED1.png',
        category: 'สินค้าอื่นๆ'
    },
    {
        id: 14,
        name: 'ถูงขยะดำ',
        description: 'Description for Product 3',
        price: '',
        imageUrl: '/images/products/blackps.png',
        category: 'สินค้าอื่นๆ'
    },
    

    // เพิ่มสินค้าอื่น ๆ ที่นี่
  ];
  
  export default products;