import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2 className="footer-title">เกี่ยวกับเรา</h2>
          <p>
            บริษัท โชคนำชัย พัฒนา จำกัด เป็นผู้นำด้านการผลิตและจำหน่ายสินค้าอุตสาหกรรม
            ด้วยประสบการณ์และความเชี่ยวชาญ เรามุ่งมั่นในการพัฒนาสินค้าและบริการให้มีคุณภาพ
            เพื่อตอบสนองความต้องการของลูกค้า
          </p>
        </div>
        <div className="footer-section links">
          <h2 className="footer-title">ลิงก์ที่มีประโยชน์</h2>
          <ul>
            <li><a href="/">หน้าแรก</a></li>
            <li><a href="/about">เกี่ยวกับเรา</a></li>
            <li><a href="/products">สินค้า</a></li>
            <li><a href="/contact">ติดต่อเรา</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2 className="footer-title">ติดต่อเรา</h2>
          <p>ที่อยู่: 4/1 หมู่.11 ซอยเทิดพระเกียรติ2 ตำบลตลาดจินดา อำเภอสามพราน นครปฐม 73110</p>
          <p>โทร: 034-981-049</p>
          <p>Fax: 034-981-048</p>
          <p>Tel: 092-296-5245</p>
          <p>อีเมล: Choknumchai1@hotmail.com</p>
        </div>
      </div>
      <div className="footer-bottom, prompt-semibold">
        &copy; {new Date().getFullYear()} Choknamchai Phattana Co., Ltd. | สงวนลิขสิทธิ์
      </div>
    </footer>
  );
}

export default Footer;