import React from 'react';
import './NewsCard.css';

function NewsCard({ title, imageUrl, date, description }) {
  return (
    <div className="news-card">
      <img src={imageUrl} alt={title} className="news-image" />
      <div className="news-content">
        <h3 className="news-title">{title}</h3>
        <p className="news-date">{date}</p>
        <p className="news-description">{description}</p>
        <button className="read-more-button">READ MORE</button>
      </div>
    </div>
  );
}

export default NewsCard;