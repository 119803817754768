import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Header.css'; // ไฟล์ CSS สำหรับสไตล์ของ Header
import MobileMenu from './MobileMenu'; // นำเข้า MobileMenu component

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleResize = () => {
    if (window.innerWidth > 1000) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      <div className="top-bar">
        <div className="top-bar-content">
          <div className="company-name">
            บริษัท โชคนำชัย พัฒนา จำกัด
          </div>
          <div className="language-switch">
            <Link to="/">EN</Link> | <Link to="/">ไทย</Link>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="bottom-bar-content">
          <div className="logo">
            <img src="logo512.png" alt="CNP" />
          </div>
          <button className="hamburger-button" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className="navbar">
            <ul className="nav-links">
              <li><Link to="/">หน้าแรก</Link></li>
              <li><Link to="/about">เกี่ยวกับเรา</Link></li>
              <li><Link to="/products">สินค้าของเรา</Link></li>
              <li><Link to="/sustainability">ความยั่งยืน</Link></li>
              <li><Link to="/news">ข่าวสาร</Link></li>
              <li><Link to="/contact">ติดต่อเรา</Link></li>
            </ul>
          </nav>
        </div>
      </div>
      <MobileMenu isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} /> {/* ใช้งาน MobileMenu component */}
    </header>
  );
}

export default Header;