import React from 'react';
import './KnowledgeCard.css';

function KnowledgeCard({ title, imageUrl, description }) {
  return (
    <div className="knowledge-card">
      <img src={imageUrl} alt={title} className="knowledge-image" />
      <div className="knowledge-content">
        <h3 className="knowledge-title">{title}</h3>
        <p className="knowledge-description">{description}</p>
        <button className="explore-button">EXPLORE MORE</button>
      </div>
    </div>
  );
}

export default KnowledgeCard;