import React from 'react';
import { useNavigate } from 'react-router-dom';
import products from '../data/products';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import './ProductList.css';

function ProductList() {
  const scrollRef = React.useRef(null);
  const navigate = useNavigate();

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: -320,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: 320,
        behavior: 'smooth',
      });
    }
  };

  const handleViewMore = () => {
    navigate('/products');
  };

  return (
    <div className="product-section">
      <h2 className="section-title">สินค้าของเรา</h2>
      <div className="product-list-container">
        <button className="scroll-button left" onClick={scrollLeft}>
          <MdArrowBackIos />
        </button>
        <div className="product-list" ref={scrollRef}>
          {products.map((product) => (
            <div key={product.id} className="product-item">
              <img src={product.imageUrl} alt={product.name} className="product-image" />
              <div className="product-details">
                <h2 className="product-name">{product.name}</h2>
              </div>
            </div>
          ))}
        </div>
        <button className="scroll-button right" onClick={scrollRight}>
          <MdArrowForwardIos />
        </button>
      </div>
      <div className="view-more-container">
        <button className="view-more-button" onClick={handleViewMore}>รับชมสินค้าเพิ่มเติม</button>
      </div>
    </div>
  );
}

export default ProductList;