import React from 'react';
import { Link } from 'react-router-dom';
import './MobileMenu.css';

function MobileMenu({ isOpen, toggleMenu }) {
  return (
    <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
      <ul className="mobile-nav-links">
        <li><Link to="/" onClick={toggleMenu}>หน้าแรก</Link></li>
        <li><Link to="/about" onClick={toggleMenu}>เกี่ยวกับเรา</Link></li>
        <li><Link to="/products" onClick={toggleMenu}>สินค้าของเรา</Link></li>
        <li><Link to="/sustainability" onClick={toggleMenu}>ความยั่งยืน</Link></li>
        <li><Link to="/news" onClick={toggleMenu}>ข่าวสาร</Link></li>
        <li><Link to="/contact" onClick={toggleMenu}>ติดต่อเรา</Link></li>
      </ul>
    </div>
  );
}

export default MobileMenu;